.login-page {
  height: 100vh;

  .center-block {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .left-side {
    height: 100%;
    width: 26%;
    background: linear-gradient(to bottom, #eb590b, #da600d);
    position: relative;

    .bg-block {
      background: url("../../../../assets/images/component/background.png") center/44.8125rem 52.1875rem no-repeat;
      height: 100%;
      color: #fff;
      padding: 0 0.9375rem;
      flex-direction: column;

      .logo-box-wrapper {
        flex: 0;
        background: transparent;

        .logo-box {
          background-image: url("../../../../assets/images/component/pwc-logo-dark.1bba3afba56a5ebe2c95.svg");
          width: 6.25rem;
          height: 4.5rem;
          background-size: contain;
          background-repeat: no-repeat;
          filter: brightness(10);
        }
      }

      .notice-text {
        h2 {
          font-size: 35px;
          line-height: 35px;
          font-weight: 500;
          color: #fff;
          margin-top: 30px;
          margin-bottom: 15px;
        }

        h3 {
          font-size: 20px;
          line-height: 22px;
          opacity: 0.8;
          font-weight: 400;
          color: #fff;
          width: 70%;
          margin: 0 auto;
        }

        p {
          font-size: 0.875rem;
          line-height: 1.64;

          .a-link {
            color: #fff;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .right-side {
    height: 100%;
    width: 74%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;

    .content {
      min-height: 100%;
      height: auto;
      flex-wrap: wrap;
      position: relative;
    }

    .login-box.new {
      flex: auto;
      width: 100%;
      color: #2d2d2d;
      padding: 1.875rem 0;
      padding-left: 75px;
      padding-right: 75px;

      form {
        max-width: 520px;
        width: 100%;
      }

      .terms-text {
        a {
          color: #ec590a;
          font-weight: 500;
        }
      }

      .logo-box-wrapper {
        flex: 1 0 100%;
        margin-bottom: 4.375rem;

        .logo-box {
          background-image: url("../../../../assets/images/component/pwc-logo-dark.1bba3afba56a5ebe2c95.svg");
          width: 6.25rem;
          height: 4.5rem;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .form-group {
        text-transform: uppercase;
        line-height: 0;
        margin-bottom: 0;
        position: relative;

        label {
          &:not(.a-checkbox) {
            font-size: 0.75rem;
            line-height: 1.58;
            margin-bottom: 0.625rem;
          }
        }

        .a-form-label {
          font-size: 12px;
          font-weight: 500;
        }

        input {
          background-color: #f5f5f5;
          border: #eb590b;
          height: 3.125rem;
          border-radius: 0.125rem;
          color: #2d2d2d;

          &:focus {
            box-shadow: 0 0 0 0.125rem #eb590b;
            background-color: #fff;
          }
        }

        .invalid-feedback {
          position: absolute;
          bottom: -0.625rem;
        }

        .a-error-msg {
          position: absolute;
          text-transform: none;
          padding-top: 0.625rem;

          div {
            font-size: 0.875rem;
            line-height: 1.71;
            color: #e0301e;
          }
        }

        div {
          &.error {
            float: none;
            margin-top: 5px;
            line-height: 16px;
            font-size: 0.8rem;
            text-transform: none;
          }
        }
      }

      .form-group.mb-20 {
        margin-bottom: 1.25rem;
      }

      .form-group.login-control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;

        .a-btn.a-btn-primary {
          background-color: #eb590b;
          color: #ffffff;
          width: 100%;
        }

        .a-checkbox {
          text-transform: none;
          line-height: 20px;
          display: inline-block;
          color: #1d1d1d;
          width: 100%;
          font-size: 13px;

          a {
            color: #eb590b;
          }

          input[type="checkbox"] {
            &+.a-checkbox-mark {
              .appkiticon {
                display: inline-block;
                visibility: hidden;
              }
            }

            &:checked {
              &+.a-checkbox-mark {
                .appkiticon {
                  visibility: visible;
                }
              }
            }
          }
        }

        .login-btn {
          width: 5.4375rem;
          text-transform: uppercase;
          justify-content: center;

          .a-loader-icon {
            display: inline-block;
          }
        }
      }

      .notice-text {
        h2 {
          font-size: 1.3rem;
          font-weight: 500;
          line-height: 1.58;
          margin-bottom: 1.5rem;
        }

        h3 {
          font-size: 1rem;
          line-height: 2.38;
          margin-bottom: 3.125rem;
          font-weight: 400;
        }
      }

      .dashkit-text {
        margin-top: 2rem;
        font-size: 0.875rem;
      }
    }

    .new-registration-box {
      flex: auto;

      width: 100%;
      color: #2d2d2d;
      padding: 1.875rem 0 6.25rem;

      padding-left: 75px;

      .logo-box-wrapper {
        .logo-box {
          background-image: url("../../../../assets/images/component/pwc-logo-dark.1bba3afba56a5ebe2c95.svg");
          width: 6.25rem;
          height: 4.5rem;
          background-size: contain;
          background-repeat: no-repeat;
          margin-bottom: 4.375rem;
        }
      }

      .notice-text {
        h2 {
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 1.5rem;
        }
      }

      form {
        max-width: 520px;
        width: 100%;

        .form-group {
          .a-form-label {
            font-size: 12px;
            font-weight: 500;

            .small-label {
              font-weight: 400;
              text-transform: capitalize;
            }
          }

          .a-form-label-text {
            font-size: 12px;
            color: #353535;
            text-transform: none;
            margin-bottom: 27px;
          }

          .registration-button {
            width: 100%;

            button {
              width: 100%;
            }
          }

          input {
            height: 38px;
          }
        }

        .login-info {
          margin-top: 15px;
          font-size: 12px;
          font-weight: 400;

          a {
            font-weight: 500;
            color: #ec590a;
          }
        }

        .terms-text {
          font-size: 12px;

          a {
            color: #ec590a;
            font-weight: 500;
          }
        }

        .phoneInputFlag {
          .react-tel-input {
            .form-control {
              background: #f5f5f5;
              border: 1px solid transparent;
              border-radius: 0.125rem;
              background-color: #f5f5f5;
              color: #2d2d2d;
              padding: 0 0.625rem;
              font-size: 0.875rem;
            }
          }
        }
      }

      .at-footer {
        width: auto;

        p {
          color: #a3a3a3;
          font-size: 10px !important;
        }
      }
    }

    .footer {
      flex: 1 0 100%;
      font-size: 0.75rem;
      color: #9e9e9e;
      line-height: 1.58;
      justify-content: center;
      display: flex;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 1.875rem;

      p {
        flex-basis: 27.5rem;
        margin-bottom: 0;
      }
    }
  }

  &.mfa-prompt {
    .right-side {
      .login-box {

        .mp-text-field,
        .login-control {
          width: 100%;
          max-width: 420px;
          margin: 0 auto;

          .a-btn.a-btn-primary {
            background-color: #eb590b;
            color: #ffffff;
          }

          .appkiticon {
            font-size: 24px;
            width: 10%;
          }

          span {
            width: 90%;
            display: inline-block;
            color: #a3a3a3;
            line-height: 1.2;
            vertical-align: top;
            text-transform: none;
          }

          .skip-button {
            color: #fa5928;
            font-size: 0.875rem;
            cursor: pointer;
          }
        }

        .at-footer {
          left: 50%;
          transform: translateX(-50%);
        }

        .notice-text {
          padding: 0 10px;

          h3 {
            font-size: 0.85rem;
            font-weight: 500;
            color: #676767;
          }

          h2 {
            color: #4a4a4a;
          }
        }
      }
    }
  }
}

.left-footer-text.text-left {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 30px;
  width: 70%;

  p {
    color: #fff;
  }
}

.at-footer {
  p {
    color: #a3a3a3;
    font-size: 10px !important;
  }

  width: 100%;
  position: absolute;
  margin: 0;
  bottom: 22px;
  max-width: 100%;
  padding-right: 80px;
}

.public-header {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-flow: row wrap;
  padding-top: 95px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d0d0d1;
  background-color: #fff;

  .app-title {
    font-size: 22px;
  }

  .a-pwc-logo-grid {
    width: 90px;
    height: 65px;
    background-size: 85px;
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}
.nav-data {
  color: 'e8613a';
  // background-color: white;
}
.public-header-privacy {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-flow: row wrap;
  padding-top: 46px;
  padding-bottom: 30px;
  background-color: #fff;
  .app-title {
    font-size: 22px;
  }
  .a-pwc-logo-grid {
    width: 106px;
    height: 80px;
  }
}

.public-container {
  position: relative;
  background-color: #E9E7E9;
  overflow-y: auto;

  .privacy-content {
    .container {
      background-color: rgba(0, 0, 0, 0);
      max-width: 40rem;
      margin: 4.9rem auto 0;

      .privacy-content-container {
        padding: 25px;
        width: 100%;
        margin: 0 auto;
        max-width: 850px;

        .privacy-list {
          padding-left: 3rem;

          li {
            list-style: disc;
          }
        }

        .padding-formatting {
          padding-left: 30px;
        }

        .app-buttons {
          margin-bottom: 40px;

          img {
            width: 165px;
          }
        }

        p {
          margin-top: 0;
          margin-bottom: 15px;
          font-size: 0.95rem;
          color: #000;
        }

        a {
          &.hyperlink {
            color: #0000EE
          }

          text-decoration: underline;
        }

        h5 {
          color: #4a4a4a;
        }

        h6 {
          color: #000000;
        }

        i {
          font-style: italic;
        }

        .terms-of-use-item {
          a {
            color: #0000EE;
          }

          .term-pd-1 {
            padding-left: 1.5rem;
          }

          .term-pd-2 {
            padding-left: 2.8rem;
          }

          .term-pd-3 {
            padding-left: 4.8rem;
          }
        }
      }
    }
  }

  .lower-right-image {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.mobile-button-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .loader-button,
  .loader-button-skip {
    border: none !important;
  }
}

.flag-dropdown .flag {
  background-size: auto;
}

.react-tel-input input[type="text"],
.react-tel-input input[type="tel"] {
  margin-left: 62px;
  padding-left: 10px;
  box-shadow: none;
  width: calc(100% - 62px);
}

.react-tel-input input[type="tel"]:focus {
  border: 1px solid #ec590a !important;
  box-shadow: none !important;
  background: #f5f5f5 !important;
}

.react-tel-input .flag-dropdown {
  width: 55px;
}

.react-tel-input .flag-dropdown.open {
  background: #f5f5f5;

  .selected-flag {
    background: transparent !important;
  }
}

.react-tel-input .flag-dropdown {
  width: 55px;
  background: #f5f5f5 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 2px !important;
}

.react-tel-input .country-list .country-name {
  font-size: 12px;
  color: #000;
  text-transform: capitalize;
}

.react-tel-input .selected-flag {
  background: transparent !important;
  padding: 0;
  margin-left: 9px;
}

.react-tel-input .selected-flag .arrow {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  left: 24px;
  width: 6px;
  height: 6px;
  border-right: none;
  border-top: none;
  background: transparent;
  border-left: 1px solid #5d5d5d;
  border-bottom: 1px solid #5d5d5d;
  border-radius: 0 0 0 1px;
  transform: rotate(-45deg);
}

.react-tel-input .country-list {
  left: 0;
  top: 45px;
}

.react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  background: #f5f5f5;
  border-radius: 3px 0 0 0;
}

.react-tel-input .flag-dropdown.open-dropdown {
  background: #f5f5f5;
  border-bottom: 0;
  border-radius: 3px 0 0 0;
  border: 1px solid #ec590a;
  box-shadow: none;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-left: 1px solid #5d5d5d;
  border-bottom: 1px solid #5d5d5d;
}

.react-tel-input .flag-dropdown:hover .selected-flag {
  background-color: transparent;
}

.selected-flag:focus {
  outline: none;
}

.react-tel-input {
  width: auto;
}

.country-list::-webkit-scrollbar {
  width: 5px;
}

.country-list::-webkit-scrollbar-track {
  -webkit-box-shadow: #fff;
  border-radius: 10px;
}

.country-list::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #d8d8d8;
}

.required-star.color-red {
  color: red;
  font-size: 13px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .phoneInputFlag {
    margin-bottom: 20px;
  }

  .login-page .right-side .new-registration-box form .form-group {
    margin-bottom: 0;
  }

  .login-page .right-side .new-registration-box form .form-group input {
    margin-bottom: 20px;
  }

  .login-page .right-side .new-registration-box form .form-group div.error {
    margin-top: -16px;
  }

  .left-side {
    display: none;
  }

  .login-page .right-side {
    width: 100%;
  }

  .login-page .right-side .login-box.new {
    padding: 25px !important;
    min-width: auto;
    padding-bottom: 0 !important;
  }

  .terms-text.checkbox {
    margin-bottom: 0;
  }

  .login-page .right-side .login-box.new .at-footer p {
    margin-bottom: 0;
    font-size: 10px;
  }

  .login-page .right-side .login-box.new .at-footer {
    right: 25px;
    left: 25px;
    width: 88%;
  }

  .login-page .right-side .new-registration-box {
    padding: 25px !important;
    min-width: auto;
  }

  .login-page .right-side .new-registration-box form .terms-text {
    padding-bottom: 100px;
  }

  .login-page .right-side .new-registration-box .at-footer p {
    margin-bottom: 0;
    font-size: 10px;
  }

  .login-page .right-side .new-registration-box .at-footer {
    right: 25px;
    left: 25px;
  }

  .react-tel-input .country-list {
    width: 300px !important;
  }
}

.haveAccount {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;

  a {
    color: #ed5903;
    margin-left: 8px;
  }
}

.phoneInputFlag .form-control {
  background-color: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0.125rem;
}

.phoneInputFlag {
  position: relative;
}

// .phoneInputFlag:before {
//   content: "+244";
//   position: absolute;
//   z-index: 9;
//   left: 73px;
//   top: 50%;
//   transform: translateY(-50%);
//   /* opacity: 0; */
//   font-size: 0.875rem;
//   color: red;
// }

.form-group.login-loader-container {
  height: 100vh;
  margin-bottom: -30px !important;
  margin-top: -30px;
}

.form-group.login-loader-container .theme-loader {
  transform: translate(-50%, -50%);
}

.loader-text {
  color: #3a3838;
  margin-top: 5px;
}

.t-invalid-input {
  border: 1.5px solid red !important;
  box-shadow: 0 0 0 #fa5927 !important;
}

.small-label.lp-help i {
  font-size: 16px !important;
  color: #b2b2b2;
}

.small-label.lp-help {
  margin-bottom: 3px;
}

.lp-help-tooltip {
  width: 250px;
  height: auto !important;
  text-align: justify;
}

.resend-btn {
  font-size: 0.85rem !important;
  min-height: 0 !important;
}

.a-alert.activation-alert {
  margin: 0;
  width: 100%;
  max-width: 100%;
  border-radius: 0;
  left: 0;
  transform: none;
  opacity: 1 !important;

  .a-alert-content {
    justify-content: center;

    .a-content {
      color: #fff;
    }
  }
}

.a-alert.activation-alert.success {
  background-color: #22992e !important;
}

.activation-wrap {
  flex: auto;
  width: 100%;
  color: #2d2d2d;
  padding: 1.875rem 0;
  padding-left: 75px;
  padding-right: 75px;
}

.activation-wrap .activation-inner {
  max-width: 600px;
  width: 100%;
}

.activation-wrap .activation-inner h4 {
  font-size: 19px;
  font-weight: bold;
}

.activation-inner p {
  font-size: 13px !important;
  font-family: "PwC Helvetica Neue";
}

.activation-wrap .activation-inner .a-btn.bt-login {
  font-size: 13px;
}

.at-footer p {
  color: #a3a3a3;
  font-size: 10px !important;
}

.activation-wrap .activation-inner p a {
  color: #d04a02;
}

.form-group {
  div.error {
    float: right;
    color: red;
    font-size: 0.75rem;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

div.error {
  float: right;
  color: red;
  font-size: 0.75rem;
  margin-top: 4px;
  margin-bottom: 4px;
}

.a-font-sm {
  font-size: 0.75rem !important;
}

.a-loading-one {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background-image: url(https://sense-dev.pwc.com.au/assets/25a6699af2c37e2b97c8b1d8e8d89b45.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: load3 0.6s infinite linear;
  animation: load3 0.6s infinite linear;
}