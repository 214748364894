* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.comment-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6c757d;
  font-family: "PwC Helvetica Neue" !important;
  font-weight: 400;
}

.comment-box:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #6c757d;
  font-family: "PwC Helvetica Neue" !important;
  font-weight: 400;
}

.comment-box::-ms-input-placeholder { /* Microsoft Edge */
  color: #6c757d;
  font-family: "PwC Helvetica Neue" !important;
  font-weight: 400;
}

.t-btn-text, body small, body text,
body h1, body h2, body h3, body h4, body h5, body h6, body h7, body h8,
body b, body strong, body li, body spam, body label,
body span, body a, body div, body p, html, html form {
  font-family: "PwC Helvetica Neue" !important;
}

.wrapper {
  height: calc(100vh - 45px);
  display: flex;
  align-items: center;
}
.wrapperorientation {
  height: calc(100vh - 45px);
  display: flex;
  align-items: center;
  background-color: black;
}
.cards-wrapper {
  height: calc(100vh);
}
.content-title {
  font-size: 20px;
  font-weight: bold;
  color: #6e6e6e;
  display: block;
  margin-top: 10px;
}
.landscape-title {
  font-size: 17px;
  font-weight: bold;
  color: #9e9e9e;
  display: block;
  margin-top: 34px;
}
.landscape-container {
  text-align: center;
  margin: 0 auto;
}

.description-container {
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.description {
  width: 50%;
  margin: 0 auto;
  font-size: 16px;
  color: #6e6e6e;
  display: block;
  line-height: 1.44;
}

.action-btn {
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  background-color: #e05d0c;
  color: #fff !important;
  width: 200px !important;
  padding: 10px 0 !important;
  box-shadow: none !important;
  height: auto !important;
}
.card-section .response-buttons-container .open-text-input-container input.open-text-input.comment-box:focus-visible {
  outline: none;
}
.card-section .response-buttons-container .open-text-input-container input.open-text-input:focus-visible {
  outline: none;
}
/* <-----FOOTER------> */

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
}

.footer-link {
  color: #6d6d6d !important;
  font-size: 14px;
  padding-right: 5px;
  padding-left: 5px;
  text-decoration: underline;
  border-right: 1px solid #dddddd;
}
.footer-link:last-child {
  padding-right: 0px;
  border: none;
}

/* <----invalid-Link-----> */

.invalid-img img {
  width: 130px;
}

/* <---Download-Link----> */
.download-page-wrapper {
  height: calc(100vh - 63px);
  display: flex;
  align-items: center;
}

.response-status-icon img {
  width: 130px;
  padding-bottom: 20px;
}
.response-status-container {
  display: inline-flex;
}
.response-status-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25px;
  justify-content: center;
}
.response {
  color: #22992e;
  font-size: 25px;
  font-weight: bold;
}
.response-status {
  color: #6e6e6e;
  font-size: 16px;
  line-height: 20px;
}
.download-info {
  display: inline-block;
  font-size: 20px;
  text-align: left;
  color: #6e6e6e;
  line-height: 23px;
  font-weight: bold;
}
.download-info-new {
  display: inline-block;
  font-size: 18px;
  text-align: left;
  color: #6e6e6e;
  line-height: 23px;
  font-weight: bold;
}
.special-text {
  color: #e95a0a;
  font-weight: bold;
}
.tagline {
  font-size: 16px;
  display: inline-block;
  line-height: 20px;
  text-align: left;
  color: #6e6e6e;
  margin-top: 10px;
  margin-right: 20px;
}
.application-screen img {
  width: 320px;
  height: auto;
}

.download-link {
  display: block;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
.download-link img {
  width: 140px;
}
.download-link-container {
  align-items: center;
  display: flex;

  justify-content: center;
  margin-top: 15px;
}
.ipad-visible {
  display: none !important;
}

.mobile-tagline {
  font-size: 11px;
  display: inline-block;
  line-height: 15px;
  text-align: left;
  color: #6e6e6e;
  margin-top: 10px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .description {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .get-started-img img {
    object-fit: cover;
    width: 500px;
  }
  .description {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .get-started-img img {
    object-fit: cover;
    width: 55%;
    height: auto;
  }
}

/* <---Download-Link----> */

@media (min-width: 768px) and (max-width: 991.98px) {
  .download-page-wrapper {
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .download-link {
    margin-right: 10px;
  }
  .response {
    font-size: 30px;
  }
  .response-status {
    font-size: 20px;
    line-height: 1.37;
  }
  .response-status-icon img {
    width: 130px;
  }
  .download-info {
    font-size: 30px;
    line-height: 1.23;
    text-align: center;
  }
  .tagline {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    line-height: 27px;
  }
  .download-info-new {
    font-size: 24px;

    line-height: 33px;
  }
  .download-description-container {
    margin-top: 30px;
    text-align: center !important;
  }
  .download-link img {
    width: 214px;
  }
  .download-link {
    margin-left: 20px;
    margin-right: 20px;
  }

  .application-screen img {
    width: 520px;
    margin-top: 25px;
    margin-bottom: 60px;
  }
  .footer-wrapper {
    padding: 17px 30px;
    background: #fff;
  }
  .footer-img img {
    width: auto;
  }
  .footer-link {
    font-size: 16px;
  }
  .download-link-container {
    flex-direction: row;
  }
  footer.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
  }
  .ipad-hidden {
    display: none !important;
  }
  .ipad-visible {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .download-page-wrapper {
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .application-screen img {
    // width: 100%;
    // height: 435px;
    // object-fit: cover;
    // object-position: top;
    width: 100%;
    object-fit: cover;
    object-position: top;
    margin-bottom: 50px;
  }
  .response {
    font-size: 30px;
  }
  .main-content {
    margin-top: 30px;
  }
  .download-description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .response-status {
    font-size: 20px;
    line-height: 1.37;
  }
  .response-status-icon img {
    width: 130px;
  }
  .download-info {
    font-size: 28px;
    line-height: 1.23;
    text-align: left;
  }
  .tagline {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 27px;
  }
  .download-info-new {
    font-size: 30px;

    line-height: 33px;
  }
  .download-link img {
    width: 214px;
  }
  footer.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
  }
  .footer-wrapper .footer-img img {
    width: auto;
  }
  .footer-wrapper {
    padding: 10px 30px;
  }
  .ipad-hidden {
    display: none !important;
  }
  .ipad-visible {
    display: inline-block !important;
  }
}

@media only screen and (device-width: 768px) {
  .ipad-hidden {
    display: none !important;
  }
  .ipad-visible {
    display: inline-block !important;
  }
  .download-page-wrapper {
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;

    .download-link-container {
      flex-direction: row;
    }
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ipad-hidden {
    display: none !important;
  }
  .ipad-visible {
    display: inline-block !important;
  }
  .download-page-wrapper {
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .ipad-hidden {
    display: none !important;
  }
  .ipad-visible {
    display: inline-block !important;
  }
  .download-page-wrapper {
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
}

@media (min-width: 1200px) {
}

/*loader css*/
.loader-logo img {
  width: 150px;
  margin-top: 35px;
}

.loader {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%);
}

.loader img {
  width: 38px;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-content {
  .press-enter {
    display: block;
  }
}

.press-enter {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #d7d7d7;
  margin-top: 6px;
}

@media screen and (max-width: 320px) {
  .footer-img {
    padding: 0px 0 15px 0;
  }
}

@media screen and (min-width: 321px) and (max-width: 767px) {
  .footer-img {
    padding: 0px 0 15px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .desktop-qrcode {
    display: none;
  }
}

.download-page-wrapper.final-response-container {
  background-color: white;
  max-height: calc(100vh - 150px);
  max-width: 100%;
  overflow-x: hidden;

  .download-info-new {
    margin-left: 20px;
  }
  display: block;
  .response-status-container {
    display: block; 
    text-align: center;
    background: #f9f9f9;
    padding-top: 50px;
    padding-bottom: 30px;

    .response-status-text {
      span {
        display: block;
        width: 100%;
        text-align: center !important;
        padding-bottom: 5px;
        &.response-status {
          font-size: 15px;
        }
      }
    }
  }
  .application-screen {
    img {
      width: 150px;
      height: auto;
    }
  }
  .download-link-container {
    justify-content: start;
    .download-link:first-child {
      margin-left: 0;
    }
  }
}
.visible-ipad-only {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .download-page-wrapper.final-response-container {
    .application-screen {
      img {
        width: 150px;
        height: auto;
        margin: 20px 0 !important;
      }
    }
    .download-description-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
    .download-link-container {
      justify-content: center;
    }
  }
  .tagline {
    margin: 0;
  }
  .visible-desktop-only {
    margin-bottom: 0 !important;
  }
}
.tagline-message {
  font-size: 17px;
  width: 65% !important;
  margin: 0 auto;
}
.bg-grey {
  background: #f9f9f9;
}

pre {
  font-family: Arial;
  color: #6e6e6e;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-x: hidden;
}

.contentWrapper {
  width: 85%;
  max-width: 1140px;
}
.logo-text {
  opacity: 0.4;
}
.loader-logo .logo-text {
  font-size: 19px;
  font-weight: bold;
}

.inflix-download-heading {
  color: #6e6e6e;
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 8px;
}

.tagline-inflix {
  font-size: 1em;
  display: inline-block;
  line-height: 20px;
  color: #6e6e6e;
}

@media screen and (max-width: 1280px) {
  .action-btn {
    line-height: 15px;
}
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
}

.get-started-btn {
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  background-color: #e05d0c;
  color: #fff !important;
  width: 200px !important;
  border-radius: 0.125rem;
  padding: 10px 0 !important;
  box-shadow: none !important;
  height: auto !important;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0.375rem 0.75rem !important;
}