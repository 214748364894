.data-scroller-container {
  overflow: auto;
  min-height: 400px;
  height: calc(100vh - 220px);

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
  }
}

div {
  display: block;
}
.swiper-slide-active {
  height: auto !important;
}
.card span {
  display: unset;
  font-size: unset;
}
.user-delete-modal {
    margin: auto;
}
.user-delete-modal .modal-dialog {
  max-width: 580px;
}
.card-section {
  .orange-bg-image {
    max-height: 20vh;
    min-height: 150px;
    background-size: cover;
    width: 100%;
    position: absolute;
  }
  

  .c-card-container {
    min-height: 300px;
    height: 40vh;
    display: flex;
    justify-content: center;
    position: relative;

    .c-card {
      padding: 50px;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      min-height: 164px;
      border-radius: 5px;
      transform: translateY(-50%);
      top: 50%;
      background-color: #fff;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 50%;
      margin-left: -400px;
      border: 1px solid rgb(0 0 0 / 0.21);
      max-height: 164px;

      &:not(.active-card) {
        .c-data-wrapper {
          max-height: 60px;
        }
      }

      .c-data-wrapper {
        .c-card-number {
          position: absolute;
          top: 10px;
          left: 10px;

          p {
            margin: 0;
            font-size: 25px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;            
            color: #C0C0C0;

            .c-card-total-number {
              font-size: 14px;
              font-weight: normal;
            }

            .c-card-divider {
              font-weight: normal;
            }
          }
        }

        .c-card-data {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #6e6e6e;
          margin-bottom: 10px;          
        }
        .c-card-size {
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #6e6e6e;
          word-break: break-all;
          margin-bottom: 10px;
        }

        .max-data-count {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #5d5b5b;
          display: block;
        }
      }
    }
    .card-stack-1 {
      box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 0.30);
    }
  }

 
  .response-buttons-container {
    min-height: 150px;
    margin-bottom: 50px;

    .response-button {
      width: 97px;
      height: 97px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
      border: solid 1px rgba(151, 151, 151, 0.11);
      background-color: #ffffff;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      flex-direction: column;

      &.desktop-hover {
        &:hover {
          background-color: #e1e1e1;
          text-decoration: none;

          i {
            color: #fff !important;
          }
        }
      }

      small {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
      }

      .btn-agree {
        color: #dc615b;
      }

      .btn-disagree {
        color: #4eb956;
      }

      &.desktop-polarity-hover {
        &:hover {
          background-color: #e1e1e1;
          text-decoration: none;

          i {
            color: #fff !important;
          }
        }
      }

      &.hoverAdded {
        background-color: #e1e1e1;
        text-decoration: none;

        i {
          color: #fff !important;
        }
      }
      &.hoverAddedAgree {
        background-color: #e5f0ed;
        text-decoration: none;

        i {
          color: #fff !important;
        }
      }
      &.hoverAddedDisagree {
        background-color: #f8d3cf;
        text-decoration: none;

        i {
          color: #fff !important;
        }
      }

      &:focus {
        outline: none;
      }

      i {
        width: 100%;
      }
    }

    .open-text-input-container {
      max-width: 760px;
      margin: 0 auto;
      position: relative;

      input.open-text-input {
        width: 100%;
        border-bottom: 1px solid #e05d0c;
        padding: 5px;
        margin-top: 50px;
      }

      .custom-button {
        height: 44px;
        font-weight: bold;
        line-height: 44px;

        i {
          line-height: 44px;
        }
      }

      .press-enter {
        opacity: 0.31;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #6e6e6e;
        margin-left: 15px;
      }
    }

    .button-text {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-top: 15px;
      display: block;
      text-align: center;

      &.agree {
        color: #4eb956;
      }

      &.disagree {
        color: #d95f59;
      }
    }
  }

  .progress-bar-section {
    max-width: 960px;
    margin: 0 auto;
    margin-top: 2%;

    .progress-bar-container {
      display: flex;
      flex-direction: column;

      .progress-info {
        opacity: 0.84;

        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(110, 110, 110, 0.56);
        margin-bottom: 10px;
      }

      .progress {
        height: 8px;
        border-radius: 3px;
        background-color: #d8d8d8;

        .progress-bar {
          position: relative;
          border-radius: 3px;
          background-color: #ea8649;
        }
      }
    }

    .skip-button {
      text-align: right;

      .custom-button {
        height: 38px;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #e05d0c;
        line-height: 38px;

        &:hover {
          background-color: rgba(254, 238, 233, 0);
        }

        &.skip-button-desktop-hover {
          &:hover {
            background-color: rgba(254, 238, 233, 0.5) !important;
          }
        }

        &.skip-hover {
          background-color: rgba(254, 238, 233, 0.5);
        }

        i {
          line-height: 38px;
        }
      }
    }
  }
}

.fade-in {
  opacity: 1;
  transition: all ease-in-out 0.2s;
}

.fade-in-getting-started {
  -webkit-animation-name: fade-in-button;
  -webkit-animation-duration: 2s;
  animation-name: fade-in-button;
  animation-duration: 2s;
}

.fade-in-button {
  -webkit-animation-name: fade-in-button;
  -webkit-animation-duration: 0.5s;
  animation-name: fade-in-button;
  animation-duration: 0.5s;
}

.fade-out-mobile-loader {
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-name: fade-out-mobile-loader;
  animation-name: fade-out-mobile-loader;
  opacity: 0;
}

.fade-out-button {
  opacity: 0;
  transition: all ease-in-out 0.3s;
}

.bottom-progress {
  margin-right: 40px;
  margin-bottom: 14px;
  flex: 1 1 auto;
  width: 100%;
}

.progress-bar-container-div {
  height: 30%;
  width : 100%; 
  display: flex; 
  justify-content: space-between;
  align-items: end;
}



@-webkit-keyframes fade-out-mobile-loader {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out-mobile-loader {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade-in-button {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// .download-info {
//   display: inline-block;
//   font-size: 20px;
//   text-align: left;
//   color: #6e6e6e;
//   line-height: 23px;
//   font-weight: bold;
// }
.mobile-download-info {
  display: inline-block;
  font-size: 14px;
  text-align: left;
  color: #6e6e6e;
  line-height: 19px;
  font-weight: bold;
}

.special-text {
  color: #e95a0a;
}

// .tagline {
//   font-size: 16px;
//   display: inline-block;
//   line-height: 20px;
//   text-align: left;
//   color: #6e6e6e;
//   margin-top: 10px;
// }
// .mobile-tagline {
//   font-size: 11px;
//   display: inline-block;
//   line-height: 15px;
//   text-align: left;
//   color: #6e6e6e;
//   margin-top: 10px;
// }

// .application-screen img {
//   width: 320px;
//   height: auto;
// }

.download-link {
  display: block;
  margin-top: 10px;
}

// .download-link img {
//   width: 170px;
// }

// .download-link-container {
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   margin-top: 15px;
// }

/* <-----FOOTER------> */

// .footer-wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 5px 30px;
// }

// .footer-link {
//   color: #6d6d6d !important;
//   font-size: 14px;
//   padding-right: 5px;
//   padding-left: 5px;
//   text-decoration: underline;
//   border-right: 1px solid #dddddd;
// }
.footer-link:last-child {
  padding-right: 0px;
  border: none;
}

.projectPercentage {
  position: absolute;
  top: 50%;
  color: #ffffff;
  width: 100%;
  height: 50;
  left: 0;
  margin-top: -25;
  text-align: center;
  line-height: 50;
  font-size: 22;
}
.projectPercentageNA {
  width: 20;
  height: 1;
  background-color: #eeeeee;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10;
}
.projectName {
  background-color: #22992e;
  width: 100%;
  border-bottom-left-radius: 20;
  border-bottom-right-radius: 20;
  padding: 0 15;
  height: 46;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}
.projectNameText {
  text-align: center;
  padding-top: 0;
  color: #ffffff;
  font-size: 15,
}
.solid-white-wrapper {
  width: 100%;
  display: inherit;
  justify-content: center;
  .solidWhite {
    border: 1px solid white;
    border-radius: 5px;
    width: 20%;
    margin-right: 5px;
  }
}

.success-content {
  height: calc(100vh - 87px);
  overflow-y: auto;
}

/* <---Download-Link----> */
.undo-btn {
  bottom: 24vh;
}
.unauth-completion-msg {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  max-width: 970px;
  padding: 0 20px;
  margin-top: 150px;
}
.graph-box-content {
  margin-top: 70px;
}
.content-height.main-content {
  min-height: calc(100vh - 235px);
  margin-top: 220px;
  display: block;
}
.insights-disabled .content-height.main-content {
  min-height: calc(100vh - 335px);
  margin-top: 250px;
}
.insights-disabled .graph-box-content {
  margin-top: 80px;
}
@media (max-width: 575px) {
  .mobile-download-page-wrapper {
    padding-top: 5%;
    overflow-y: auto;
    max-height: calc(100vh - 105px);
  }
  .project-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 260px;
  }

  .success-content {
    height: calc(100vh - 157px);
}
.undo-btn {
  bottom: 18vh;
}
.submit-response {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}

  // .download-info {
  //   width: 88%;
  //   text-align: center;
  // }

  .application-screen {
    text-align: center;
  }

  .download-description-container,
  .mobile-view-description {
    text-align: center;
  }

  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .footer-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-link {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .application-screen img {
    width: 310px;
    object-fit: cover;
    object-position: top;
  }

  footer.footer {
    position: static;
    bottom: 0;
    width: 100%;
    background: #fff;
    margin-top: 10px !important;
  }

  .mob-container {
    min-height: 100vh
  }
  /* This comment will be remove in future if not required */
  // .c-card.card-stack-1 {
  //   min-height: 220px !important;
  // }
  .fade-in-button button {
    margin-top: 40px !important;
  }
  .content-height.main-content {
    margin-top: 200px;
}
  .graph-box-content {
    margin-top: 50px;
  }
  .insights-disabled .content-height.main-content {
    min-height: calc(100vh - 335px);
    margin-top: 210px;
  }
  .insights-disabled .graph-box-content {
    margin-top: 50px;
  }
  .side-drawer {
    width: 88% !important;
}
}
@media (max-width: 300px) {
  .side-drawer {
    width: 82% !important;
}
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mobile-download-page-wrapper {
    padding-top: 4%;
    overflow-y: auto;
    max-height: calc(100vh - 45px);
  }

  .download-link img {
    width: 110px;
  }

  .download-link {
    margin-right: 10px;
  }

  .download-link-container {
    flex-direction: row;
    margin-top: 0;
    justify-content: center !important;
  }

  .mobile-view .download-link-container {
    flex-direction: column;
    margin-top: 0;
  }

  footer.footer {
    position: fixed;
    width: 100%;
    background: #fff;
    bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mobile-download-page-wrapper {
    padding-top: 4%;
    overflow-y: auto;
    max-height: calc(100vh - 45px);
  }

  // .download-link img {
  //   width: 110px;
  // }

  .download-link {
    margin-right: 10px;
  }

  // .download-link-container {
  //   flex-direction: column;
  //   margin-top: 0;
  // }

  // .application-screen img {
  //   width: 275px;
  //   height: 310px;
  //   object-fit: cover;
  //   object-position: top;
  // }

  footer.footer {
    // position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
  }
  .project-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 540px;
  }
}

.theme-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.error-message {
  opacity: 0.63;
  border-radius: 5px;
  background-color: #b41212;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.94);
  padding: 8px;
  display: inline-block;
  margin-top: 15px;
}

footer.footer-questionnaire {
  margin-top: 25px;
}

.question-content-container {
  min-height: calc(100vh - 88px);
  max-height: calc(100vh - 88px);
}

@media screen and (max-width: 540px) {
  .card-section .c-card-container .c-card {
    max-width: 470px !important;
    left: unset;
    right: 20px;
  }
}

@media screen and (max-width: 414px) {
  .card-section .c-card-container .c-card {
    left: unset;    
    right: 10px;
    max-width: 362px !important;
  }

  .c-card-data {
    font-size: 14px !important;
    vertical-align: middle;

  }
  .c-card-size {
    font-size: 12px !important;
    margin-top: 30px;
  }
  .max-data-count {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 393px) {
  .card-section .c-card-container .c-card {
    left: unset;
    right: 0;
  }
}

@media screen and (max-width: 375px) {
  .card-section .c-card-container .c-card {
    left: unset;
    right: 0;
  }

  .footer-wrapper {
    padding: 0 30px;
  }
}

@media screen and (max-width: 360px) {
  .card-section .c-card-container .c-card {
    left: unset;
    right: 0;
  }
  .card-section .response-buttons-container {
    margin-bottom: 10px;
}
}
@media screen and (max-width: 414px) { 
      .wrapper.get-start-content {
        height: calc(100vh - 136px);
        overflow-y: auto;

        .description {
          width: 80%;
          }
      }
  }


@media screen and (max-width: 280px) {
  .card-section .c-card-container .c-card {
    left: unset;
    right: 0;
  }

  .card-section .response-buttons-container .response-button {
    width: 70px;
    height: 70px;

    i {
      font-size: 25px !important;
    }
  }
}
@media screen and (max-width: 280.98px) {
  
  .skip-response-button {
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-top: 3px !important;
    padding-bottom: 4px !important;
  }
}


/** Start Of iPhone Media Query */
/* iphone 3 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) { 
  .skip-response-button {
    margin-right: 28px;
  }
}
        
/* iphone 4 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) { 
  .skip-response-button {
    margin-right: 28px;
  }
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) { 
  .skip-response-button {
    margin-right: 28px;
  }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) { 
  .skip-response-button {
    margin-right: 28px;
  }
}
    
/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) { 
  .skip-response-button {
    margin-right: 28px;
  }
}

/* iphone X , XS, 11 Pro, 12 Mini */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) { 
  .skip-response-button {
    margin-right: 28px;
  }
}

/* for iphone 12, 12 Pro, Iphone 14 Pro */
@media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) { 
  .skip-response-button {
    margin-right: 28px;
  }
}

/* iphone XR, 11 */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) { 
  .skip-response-button {
    margin-right: 28px;
  }
}
    
/* iphone XS Max, 11 Pro Max */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
  .skip-response-button {
    margin-right: 28px;
  }
}

/* iphone 12 Pro Max and Iphone Pro 14 MAX */
@media only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) { 
  .skip-response-button {
    margin-right: 28px;
  }
}

/* iphone 12 Pro Max and Iphone Pro 14 MAX */
@media only screen and (min-device-width: 430px) and (max-device-height: 932px) and (-webkit-device-pixel-ratio: 3) { 
  .skip-response-button {
    margin-right: 28px;
  }
}
/** End Of iPhone Media Query */


@media screen and (max-width: 767px) {
  .card-section .progress-bar-section .skip-button {
    text-align: center;
    margin-top: 20px;
  }
  .project-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1024px) {
  .card-section .c-card-container .c-card {
    max-width: 600px;
  }

  .c-card {
    margin-left: -300px !important;
  }
}

@media only screen and (max-width: 540px) and (min-width: 280px) {
  .card-section .c-card-container {
    max-width: 75%;
    margin: 0 auto;
  }

  body,
  html {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100vh;
    touch-action: none;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .card-section .c-card-container .c-card {
    box-shadow: none;
    border: 1px solid rgb(0 0 0 / 21%);
    padding: 16px;
  }

  .question-content-container {
    min-height: unset !important;
    max-height: calc(100vh - 88px);
  }

  .card-section .response-buttons-container .response-button {
    width: 70px;
    height: 70px;
    border-radius: 50%;

    i {
      font-size: 30px !important;
    }
  }

  .c-card {
    min-height: 180px !important;
  }

  .card-section .c-card-container {
    min-height: 280px;
    height: 35vh;
  }

  .fixheight {
    min-height: 70px !important;
  }

  // .card-section .progress-bar-section {
  //   margin-top: 2%;
  // }
}
.skip-response-button:hover {
  // opacity: 0.3;
  background-color: rgba(202, 196, 194, 0.5);
}
.skip-response-button {
  margin-bottom: 14px;
  color: #eb590b;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 1;
  margin-top: -2.95%;
  min-width: max-content;
  cursor: pointer;
  border: 2px solid #eb590b;
  border-radius: inherit;
  &:focus { 
    outline: unset
  }
}

@media only screen and (max-width: 540px) and (min-width: 415px) {
  .card-section .c-card-container {
    left: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .card-section .progress-bar-section .skip-button .custom-button {
    font-size: 12px;
  }
}

@media only screen and (device-width: 768px) {
  .press-enter {
    display: none !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .press-enter {
    display: none !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .press-enter {
    display: none !important;
  }
}

.card-section .response-buttons-container .open-text-input-container input.open-text-input.comment-box {
  border-color: #ccc;
}

.card-section .response-buttons-container button.response-button.skip-button-v2 {
  width: 70px;
  height: 70px;
  flex-direction: column;
}

.skip-button-v2 span {
  color: #707070;

  font-size: 14px;
}

.card-section .response-buttons-container .response-button.btn-agree.desktop-hover:hover i {
  color: rgb(78, 185, 86) !important;
}

.card-section .response-buttons-container .response-button.btn-disagree.desktop-hover:hover i {
  color: rgb(220, 97, 91) !important;
}

.card-section .response-buttons-container .response-button.btn-agree.desktop-polarity-hover:hover i {
  color: rgb(220, 97, 91) !important;
}

.card-section .response-buttons-container .response-button.btn-disagree.desktop-polarity-hover:hover i {
  color: rgb(78, 185, 86) !important;
}

.card-section .response-buttons-container .open-text-input-container input.open-text-input.comment-box:focus {
  border-bottom: 1px solid #e05d0c;
}

.fixheight {
  min-height: 75px;
}

button.response-button.skip-button-v2:hover {
  background: #e1e1e1;
}

.not-sure {
  font-size: 12px;
  font-weight: 600;
}

.undo-button-container {
  align-items: center;
}

.undo-response-button{
  color:white;
  background-color: #7D7D7D;
  opacity:0.70;
  padding-left: 12px;
  padding-right:12px;
  padding-top:7px;
  padding-bottom:7px;
  position: absolute;
  z-index: 2;
  min-width: max-content;
}

.arrowUndo {
  font-size: 18spx;
}

.add-comment input.open-text-input {
  margin-top: 0 !important;
}

.limit_char {
  position: absolute;
  right: 0px;
  top: 45px;
  font-size: 13px;
  color: #999;
}

.cus_input_info {
  padding-bottom: 10px !important;
  padding-left: 0 !important;
}

.limit_error_msg {
  left: 190px;
  top: 45px;
  font-size: 13px;
  color: red;
}

.error-txt {
  color: red !important;
}

.limit_char2 {
  position: absolute;
  right: 0px;
  top: 96px;
  font-size: 13px;
  color: #999;
}

.limit_error_msg2 {
  position: absolute;
  right: 74px;
  top: 96px;
  font-size: 13px;
  color: red;
}

.disabled-element:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.cstm-btn-disbled {
  opacity: 0.9 !important;
  background-color: #e9e3e3 !important;
  color: #969292 !important;
  pointer-events: none !important;
}

.w-100.position-fixed {
  z-index: 99999;
}

.fixed {
  width: 220px;
  height: 120px;
  border: 1px solid black;
  background: rgb(242, 240, 240);
}

.font_size {
  background: black;
  color: white;
  width: 100px;
  min-height: 5px;
  // padding: 1px;
}

.project-title {
  color:#FFFF;
  padding: 4px; 
  margin-top: 4px; 
  display: inline-block;
  font-size: 12px;
  position: fixed;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  background: #7D7D7D;
  font-weight: 500;
}
.submit-response i {
  height: 24px;
  width: 24px;
  filter: invert(1);
  margin-bottom: 6px;
}

// @media screen and (min-width: 992px) and (max-width: 1199.90px) {
//   .limit_char {
//     right: 100px;
//   }
// }

// @media screen and (max-width: 991.90px) {
//   .limit_char {
//     right: 15px;
//   }
// }

.bg-orange-img {
  background-image: url("../../../../assets/images/orange-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 20px 0;
  text-align: center;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  .bg-title {
    font-weight: 500;
    font-size: 20px;
    display: block;
    margin-bottom: 30px;
  }
  .sp-label {
    background: #65ABEB;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    padding: 10px;
    display: inline-block;
    position: relative;
    bottom: -20px;
  }
}

.side-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  // height: 100vh;
  background-color: white;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  display: flex;
  flex-direction: column;
  height: 100%;

  .profile {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    background-color: #fff;
    margin-bottom: 15px;
    border-bottom: 1px solid #f5f5f5;
  }
 .profileImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
    align-items: center;
    color: #ffff;
    background-color: #EB590B;
    justify-content: center;
    padding: 5px 5px;
 }
  
  
  .profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .profile-name {
    font-size: 14px;
    color: #7d7d7d;
    font-weight: 500;
  }
  
 
  
  
  .content {
    flex-grow: 1;
    
  }
  .content ul{
  //  padding-left: 15px;
  //   margin-top: -10px;
  //   margin-bottom: 0;
  };
  .content ul li {
    position: relative;
    // margin-bottom: 5px;
    text-align: left;
  }
  
  .content li {
    color: #7d7d7d;
      font-size: 14px;
    padding: 10px;
  
  }
  .content li.selected {
    background-color: #f8ede6;
  }
  
  .all-topics{
    text-align: left;
    color: #7d7d7d;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 15px;
    margin-left: 4px;
    span {
      margin-right: 10px;
    }
  }
  .bottom-div{
  // padding-inline: 20px;
  }
  .bottom-span{
    // text-align: center;
      margin-left: 6px;
      color: #7d7d7d;
      font-size: 14px;
  }
  .li-text-container {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;
    height: 100%;
    // margin-left: 27px;
    cursor: pointer;  
  }
  .text-container{
    display: flex;
    align-items: center;
  }
  .footer-item {
    display: flex;
    align-items: center;
    padding:0 ;
    padding-block:0px;
    margin-block: 15px;
    // background-color: red;
  }
  #icon{ color: #7d7d7d;
    font-size:13px;
   }
   #closeicon{ color: #fff;
    font-size:16px;
    position: absolute;
    right: -30px;
    top: 21px;
    cursor: pointer;
   }
   .li-text{
    color:#7d7d7d;
    margin-left: 6px;
    // word-wrap: break-word;
    word-break: break-word;
   }
}

.side-drawer.open {
  transform: translateX(0);
  padding: 20px 16px;
}


.drawer {
  position: relative;
  z-index: 9;
  .hamburger {
    position: fixed;
    top: 10px;
    left: 16px;
    // z-index: 1000;
     
  }
  .drawer-icon {
    position: absolute;
    left: 16px;
    top: 16px;
  }
  
  
  
  
  
  .hamburger-line {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #000;
    margin-bottom: 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  
}
.insight-info {
  h3 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
    color: #7D7D7D;
  }
  p.textP {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px !important;
    max-width: 350px;
    margin: 0 auto;
  }
}
.image-section {
    text-align: center;
    min-height: calc(100vh - 220px);
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      margin-top: 40px;
    }
  p {
    font-weight: 500;
    font-size: 20px;
    color: #7D7D7D;
    margin-bottom: 36px;
  }
  img {
    margin-bottom: 40px;
  }
  .a-btn {
    background: #EC590A !important;
    width: 224px;
    border-radius: 5px;
    text-transform: uppercase;
    &:hover {
      color: #ffffff;
    }
  }
}
.info-title {
  background: #F7F7F7;
  border: 1px solid #E9E9E9;
  border-radius: 1px;
  padding: 10px;
  color: #7d7d7d;
  text-align: center;
  margin-bottom: 60px;
  display: inline-block;
}

.main-content {
  min-height: calc(100vh - 242px);
  height: 100%;
  overflow-y: auto;
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-wrap {
    width: 100%;
  }
  .content {
    text-align: center;
    h2.name {
      font-size: 20px;
      font-weight: 500;
      color: #7d7d7d;
      margin-bottom: 30px;
    }
    span.date {
      font-size: 14px;
      color: #bdbdbd;
    }
    p.desc {
      color: #BDBDBD;
    font-size: 14px;
    padding-top: 20px;
    max-width: 340px;
    margin: 0 auto;
    }
    .options {
      margin-top: 20px;
      margin-bottom: 20px;
      .butn {
        background: #828282;
        border-radius: 20px;
        padding: 10px;
        color: #fff;
        font-size: 14px;
        }
        .butn-theme {
          background: #CF4B01;
        }
      }
    }
  .mySwiper {
    img {
      width: 270px !important;
      margin: 0 auto;
    }
    .swiper-pagination-bullet {
      background: transparent;
      border: 1px solid #7D7D7D;
      opacity: 1;
      width: 10px;
      height: 10px;
    }
    .swiper-pagination {
      position: absolute;
      text-align: center;
      transition: 300ms opacity;
      transform: translate3d(0, 0, 0);
      z-index: 10;
      height: 10px;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #7D7D7D;
    }
    .circular-bar {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      margin-top: 30px;
    }
  }
}
.accordian-wrap {
  max-width: 360px;
  margin: 0 auto;
  
  .icons-list {
    text-align: right;
padding-right: 30px;
margin-bottom: 14px;
    img:last-child {
      margin-left: 40px;
    }
  }
  .custom-accordion {
    background-color: #fff;
    cursor: pointer;
    padding: 6px 20px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
    border: 1px solid #E9E9E9;
    border-radius: 100px;
    align-items: center;
    margin-bottom: 6px;
    .domain-name {
      img {
        width: 14px;
        margin-right: 10px;
      }
      .name {
        color: #7D7D7D;
        font-size: 14px;
        font-weight: 500;
        margin-right: 10px;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
         
      }
    }
    .values span {
      background: #FFFFFF;
      border: 1px solid #298C3F;
      border-radius: 5px;
      padding: 6px 10px;
      color: #388A44;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      display: inline-block;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  
  .panel {
    padding: 6px 20px;
    display: none;
    background-color: white;
    overflow: hidden;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    margin-bottom: 6px;
    box-shadow: unset;
    p {
      margin: 0;
      color: #7D7D7D;
    font-size: 14px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .main-content {
    align-items: unset;
}
}
@media (min-width: 1200.98px) and (max-width: 1280.98px) { 
  .image-section .content p {
    margin-bottom: 16px;
}

.image-section img {
  margin-bottom: 20px;
}
}
@media (max-width: 540.98px) { 
  .main-content {
    height: 100%;
    // margin-top: 23px;
    .content {
      text-align: center;
      margin-bottom: 56px;
  }
  }
  .info-title {
    margin-bottom: 30px;
    margin-top: 34px;
  }
  .bg-orange-img .bg-title {
    font-size: 18px;
    text-align: left;
  }
  .bg-orange-img .bg-auth-title {
    margin-bottom: 0;
  }
.image-section {
  min-height: calc(100vh - 350px);
}
.bg-orange-img .bg-title.mb-resp  {
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
}
@media (max-width: 360.98px) { 
  .accordian-wrap .custom-accordion .values span {
    padding: 6px 6px;
    &:first-child {
      margin-right: 6px;
    }
}
}

.form-group {
  margin-bottom: 25px;

  .a-toggle {
    font-weight: normal;

    .a-toggle-mark {
      min-width: 55px;
      width: 70px !important;
    height: 27px !important;
      .on {
        color: #fff;
        justify-content: flex-start;
        padding-left: 9px;
      }
      .off {
        justify-content: flex-end;
        padding-right: 9px;
        color: #fff;
      }
    }

    .a-toggle-text {
      font-size: 14px;
    }
  }
}
.a-checkbox {
  .a-checkbox-text {
    font-weight: 500;
    color: #424242;
    overflow-wrap: anywhere;
  }
}
.header {
  font-size: 80px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}

.a-alert.activation-alert {
  margin: 0;
  width: 100%;
  max-width: 100%;
  border-radius: 0;
  left: 0;
  transform: none;

  .a-alert-content {
    justify-content: center;

    .a-content {
      color: #fff;
    }
  }
}

.a-alert.activation-alert.success {
  background-color: #22992e !important;
}
.footer-btm {
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.notify-toggle {
  margin-bottom: 20px;
  label {
    margin-right: 14px !important;
  }
  span {
    color: #7D7D7D;
    font-weight: 500;
    margin-bottom: 2px;
    display: inline-block;
    font-size: 14px;
  }
  p {
    font-size: 14px;
    color: #BDBDBD;
    font-weight: 400;
    margin: 0;
  }
}
.box-title {
  margin-bottom: 24px;
}
.help-icon {
  position: fixed;
  top: 10px;
  right: 10px; 
  z-index: 2;
  h5 {
    color: #ffffff;
    margin-left: 6px;
  }
}

.question-text-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.question-text-no-wrap {
  display: flex;
  // min-height: 80px; //may be required in feature
  align-items: center;
  padding-top: 10px;
  justify-content: center;
}
.card-question {
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6E6E6E;
}

@media (max-width: 414.98px) {   
.project-title {
  max-width: 220px;
}
}
@media (min-width: 414px) and (max-width: 575.98px) {
  .card-question {
    min-height: 146px;
  }
}
@media (max-width: 320.98px) {   
  .project-title {
    max-width: 200px;
  }
  }
  
@media (max-width: 300.98px) {   
  .project-title {
    max-width: 170px;
  }
  }