@keyframes AndroidSwipeLeft {
    from {top: 0px; transform: translateX(0rem) rotate(0deg) ;}
    to { top: 0px; transform: translateX(-30rem) rotate(-30deg) ;}  
}

@keyframes AndroidSwipeRight {
    from {top: 0px; transform: translateX(0rem) rotate(0deg) ;}
    to {top: 0px; transform: translateX(30rem) rotate(30deg) ;}
}

@keyframes AndroidSwipeTop {
    from  { top: 100px;}
    to  {top: -200px;}
}

@keyframes IOSswipeLeft {
    from {top: 0px; transform: translateX(0rem) rotate(0deg) ;}
    to { top: 0px; transform: translateX(-30rem) rotate(-30deg) ;}  
}

@keyframes IOSswipeRight {
    from {top: 0px; transform: translateX(0rem) rotate(0deg) ;}
    to {top: 0px; transform: translateX(30rem) rotate(30deg) ;}
}

@keyframes IOSswipeTop {
    from  { top: 100px;}
    to  {top: -200px;}
}

@keyframes swipeLeft {
    from {top: 0px; transform: translateX(0rem) rotate(0deg) ;}
    to { top: 0px; transform: translateX(-30rem) rotate(-30deg) ;}  
}

@keyframes swipeRight {
    from {top: 0px; transform: translateX(0rem) rotate(0deg) ;}
    to {top: 0px; transform: translateX(30rem) rotate(30deg) ;}
}

@keyframes swipeTop {
    from  { top: 100px;}
    to  {top: -200px;}
}

